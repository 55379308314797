import ImgAudVideo from "../../separate/ImgAudVideo";
import Modal from 'react-bootstrap/Modal';
import { ImgValidation } from "../../actions/validations";
import { CreateCollectionFunc } from "../../actions/axioss/nft.axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import {toast} from 'react-toastify'
import { get_type_file, isEmpty } from "../../actions/common";
import Button from "react-bootstrap/esm/Button";
import Select from "react-select";
import config from "../config/config";

export default function CreateCollection({ SetOpenPopup ,Creator ,Network ,Type , Address ,GetCollection , collection, AccountAddress }) {

const { web3 } = useSelector(state => state.LoginReducer.AccountDetails);
const {  Categorys } = useSelector(
  (state) => state.LoginReducer);
const [BtnData, SetBtnData] = useState("start");
const [ValidateError, SetValidateError] = useState({})
const [ FormValue , SetFormValue ] = useState({
    CollectionProfileImage  :   null,
    CollectionName          :   '',
    CollectionBio           :   '',
    CollectionSymbol        :   '',
    CollectionCreator       :   Creator,
    CollectionType          :   Type,
    CollectionNetwork       :   Network,
    CollectionContractAddress : Address,
    Category            :Categorys[0].label
})

const onChange = (e) => {
    SetBtnData("start");
    SetValidateError({})
    const { files , value , id } = e.target
    console.log("files",files)
    if(id == "CollectionProfileImage") SetFormValue({...FormValue , ...{[id]:files[0]}})
    else if(id == "CollectionSymbol") SetFormValue({...FormValue,...{[id]:value.toString().replace(/\s/g,'').toLowerCase()}})
    else SetFormValue({...FormValue , ...{[id]:value}})
}

const Validation = async (data) => {
    let ValidateError = {};
    const {
        CollectionProfileImage  ,
        CollectionName,
        CollectionSymbol
       
    } = data;
    if (!CollectionName) ValidateError.CollectionName = "CollectionName Required";
    if (!CollectionSymbol) ValidateError.CollectionSymbol = "CollectionSymbol Required";
    if (!CollectionProfileImage) ValidateError.CollectionProfileImage = "CollectionProfileImage Required";
    else {
      if (ImgValidation(CollectionProfileImage, "thumb"))
        ValidateError.CollectionProfileImage = ImgValidation(CollectionProfileImage, "thumb");
    }
    return ValidateError;
};

const FormSubmit = async () => {
    SetBtnData("start");
    const id = toast.loading('Validating Form')
    var Error = await Validation(FormValue)
    SetBtnData('process')
    if (isEmpty(Error)) {
        let Respc = await CreateCollectionFunc(FormValue);
        if (Respc.success == "success") {
            SetBtnData("done");
            await GetCollection();
            SetOpenPopup("")
                toast.update(id, { render: 'Collection Created Successfully', type: 'success', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
            }
        else {
            SetBtnData("error");
            toast.update(id, { render: Respc.msg, type: 'error', isLoading: false, autoClose: 1000 ,closeButton:true,closeOnClick:true})
            SetValidateError(Respc.msg);
          }
        } else {
            SetBtnData("error");
            SetValidateError(Error);
            toast.update(id, { render: 'Check Fields', type: 'error', isLoading: false, autoClose: 1000 })
        }
    }
   



  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className="putonsale"
    >
      <Modal.Header>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>SetOpenPopup('')}
        ></button>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Collection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="approvetransaction">
          <div className="item_imgs">
            {/* <input 
                type="file"
                id = "CollectionProfileImage"
                accept="Image/*"
                autoComplete="off" 
                onChange={onChange} /> */}
            <ImgAudVideo
              file={FormValue?.CollectionProfileImage ? URL.createObjectURL(FormValue?.CollectionProfileImage) : require('../../assets/images/default_preview.png')}
              type={get_type_file(  FormValue?.CollectionProfileImage)
              }
              thumb={FormValue?.CollectionProfileImage?URL.createObjectURL(FormValue?.CollectionProfileImage): require('../../assets/images/default_preview.png')}
              from="collection"
              origFile={FormValue?.CollectionProfileImage?URL.createObjectURL(FormValue?.CollectionProfileImage): require('../../assets/images/default_preview.png')}
              className="img-fluid"
            ></ImgAudVideo>
             <div className="avatar">
           <div className="edit_profile">
           <input
                type="file"
                id = "CollectionProfileImage"
                accept="Image/*"
                autoComplete="off" 
                onChange={onChange} />
                                <i className="fa fa-pencil"></i>    
                            </div> 
                            </div>
          </div>
          
          <div className="gallery">
          <p className="putonsalede mt-4">Create Your Own Gallery Here!</p>
          </div>
          {ValidateError.CollectionProfileImage && (
              <div className="error_msg imagesCollection">
                {ValidateError.CollectionProfileImage}
              </div>
            )} 
          <div className={"tabbable-line"}>
            <div class="tab-content mt-5">
              <div class="tab-pane active" id="fixedprice">
                <div className="fixedpricedropdown mb-4">
                  <div className="formgroupsec text-left">
                    <label className="text-left mb-3">
                      Enter Collection Name
                    </label>
                    <div className="fixedpricedropdownflex">
                      <input
                        type="text"
                        autoComplete="off"
                        className=""
                        placeholder="Collection Name"
                        onChange={onChange}
                        id="CollectionName"
                        value={FormValue.CollectionName}
                      />

                      {ValidateError.CollectionName && (
                        <div className="error_msg">
                          {ValidateError.CollectionName}
                        </div>
                      )}
                    </div>

                    <label className="text-left mb-3">
                      Enter Collection Url
                    </label>
                    <div className="fixedpricedropdownflex">
                      <input
                        type="text"
                        autoComplete="off"
                        className=""
                        placeholder="Collection Url"
                        onChange={onChange}
                        id="CollectionSymbol"
                        value={FormValue.CollectionSymbol}
                      />

                      {ValidateError.CollectionSymbol && (
                        <div className="error_msg">
                          {ValidateError.CollectionSymbol}
                        </div>
                      )}
                    </div>
                    <div className="fixedpricedropdownflex">
                      {console.log('catttttttt',FormValue.Category)}
                    <Select
                          // value={FormValue.Category}
                          defaultValue={{label:FormValue.Category,value:FormValue.Category}} 
                          onChange={(e)=> SetFormValue({ ...FormValue, ...{ ['Category']: e.label } })}
                          options={Categorys}
                          classNamePrefix="react-select"
                          isSearchable={false}
                          />

                      {ValidateError.Category && (
                        <div className="error_msg">
                          {ValidateError.Category}
                        </div>
                      )}
                    </div>
                    

                    <label className="text-left mb-3">
                      Enter Collection Bio
                    </label>
                    <div className="fixedpricedropdownflex">
                      <input
                        type="text"
                        autoComplete="off"
                        className=""
                        placeholder="e.g.describe"
                        onChange={onChange}
                        id="CollectionBio"
                        />
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
         

          <div className="connecwalletbtns mt-5 d-flex justify-content-between">
            <button className="cancelpayment primary" onClick={()=>SetOpenPopup("")}>
              Cancel
            </button>
            <Button
              className="connectwallet secondary"
              onClick={BtnData == "start" ? FormSubmit : ""}
            >
              {BtnData == "start" && "Start"}
              {BtnData == "error" && "Error"}
              {BtnData == "process" && "processing"}
              {BtnData == "done" && "Done"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
