import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {BurnTokens, CreateOrder} from "../../actions/axioss/nft.axios";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { isEmpty } from '../../actions/common';
// import { BurnTokens } from "../actions/axios/nft.axios";
import config from '../../views/config/config'

export function BurnModel({closePop,owner,item , onhide}) {
  
    const push = useNavigate()
    const [Btn , SetBtn ] = useState('start')
    const[show9,setShow9]=useState(true);
    const handleClose9 = () => setShow9(false);
    const ContractCall = useContractProviderHook()
    const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
    const { payload } = useSelector(state => state.LoginReducer.User)
    const [once,setOnce]=useState(true) 
  const [Address, SetAddress] = useState('')

//     const FormSubmit    =   async()  =>  {
//         SetBtn('process')
//        const id = toast.loading('Cancel Your order')
//         var error   = await  ContractCall.Contract_Base_Validation()
//         if(error) {  
//             toast.update(id, { render:error, type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
//             SetBtn('error')
//      }
//         else{
//             if(types == "Cancel"){
//                 let cont = await ContractCall.cancel_order_721_1155(owner.NFTId)
//                 if(cont)
//                 {
//                   console.log("cancelorgerifstattement")
//                 await Back_end(id,cont.HashValue)
//                 console.log("cassssncelorgerifstattement")

//                 handleClose9()
//                 }
//                 else { 
//                     toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
//                     SetBtn('try')}
//                 }
//         else{
//             await Back_end(id,'')
//         }
        
//     }
// }

const [Error, SetError] = useState('')
const [NFTQuantity, SetNFTQuantity] = useState(item.TokenQuantity)
const [TokenBalance, SetTokenBalance] = useState('0')
console.log("xasdawqq", item)
const Validation = async () => {
    var error = {};
    console.log("NFTQuanxxxtity" , NFTQuantity , owner.NFTBalance)
    if (isEmpty(NFTQuantity)) {error.NFTQuantity  = "Token Quantity Required"}
    else if (Number(owner.NFTBalance) < Number(NFTQuantity)) { error.NFTQuantity = "NFT Quantity should be less than " + owner.NFTBalance}
    // if (owner.CoinName != 'ETH' && ((owner.TokenPrice * NFTQuantity) > TokenBalance)) {error.NFTQuantity="Insufficient Balance"}
  //  console.log("sdfsdfgfg",Number(NFTQuantity) > ( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity));
    // if (Number(NFTQuantity) > ( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)){
    //    error.NFTQuantity = `You Only have ${( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)} NFTs for Burn Other NFTs Are Staked`;
    // }
    // else return await ContractCall.Contract_Base_Validation()
    return error
 }


const FormSubmit = async () => {

    const id = toast.loading('Burn your Token')
    SetError('')
    SetBtn('process')
    var error = await Validation()
   if (!isEmpty(error)) {
     toast.update(id, { render: "Check fields", type: 'error', isLoading: false, autoClose: 1000 })
     SetBtn('error')
     SetError(error)
   }
   else {

     let cont = await ContractCall.burn_721_1155(item.ContractAddress, item.ContractType, NFTQuantity, Address, owner.NFTId)
     if (cont) {

       let Owner = {
         HashValue: cont.HashValue,
         NewTokenOwner: accountAddress,
         NFTQuantity: NFTQuantity,
         NFTId: owner.NFTId,
         NFTOwner: owner.NFTOwner,
         PutOnSale: owner.PutOnSale,
         PutOnSaleType: owner.PutOnSaleType,
         activity: "Burn",
         TP: 0,
         CN: '',
         click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`

       }
       var Balance = owner.NFTBalance - NFTQuantity;

       let Resp = await BurnTokens({ item: item, Owner: Owner, NFTId: owner.NFTId, NFTOwner: owner.NFTOwner, BurnToken: owner.NFTQuantity, Balance })
       setTimeout(() => {
         toast.update(id, { render: " Your Token  Has Burned  Successfully", type: "success", isLoading: false, autoClose: 1000 })

       }, 1000);
       SetBtn("done")
       push(`/`)
     }
     else {
       setTimeout(() => {
         toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })

       }, 1000);
       SetBtn('try')
     }
   }
  // }
 }


console.log("cancelorderitems", item)


    useEffect(() => {
      BalanceCheck();
    }, [item, owner]);
  
    async function BalanceCheck() {
      SetBtn("process");
      if(once){
        let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
        console.log("ownneerrsnftbusdasdynowbalittemmm", Nftbalance , owner?.NFTBalance , Nftbalance , owner?.NFTOwner , item.ContractType  , owner?.NFTOwner);
        
        if ((Number(Nftbalance) != Number(owner?.NFTBalance) &&  item.ContractType == '1155' ) || ( Nftbalance.toLowerCase() !=  owner?.NFTOwner.toLowerCase()  &&  item.ContractType == '721') ) {
         
    
          toast.warning("You won't buy at this moment please refresh you data");
          setTimeout(() => {
            push("/");
          }, 1000);
        }
       
      }
      SetBtn("start");
      return ()=>{ setOnce(false)}
      
    }

   return  <Modal
   size="lg"
   aria-labelledby="contained-modal-title-vcenter"
   centered
   show={show9}
 >
   <Modal.Header>
   {/* <button type="button" class="btn-close" aria-label="Close" onClick={()=>{
    closePop();
    onhide()}}></button> */}
     <Modal.Title id="contained-modal-title-vcenter" className="text-center crossfitFont vert__subpageTitle">
     Burn NFT
     </Modal.Title>
     <button className='vert__cmnModalCloserr vert__buyNowCloser' onClick={ ()=>{ closePop(); onhide();}}> <i class="fa-solid fa-xmark" /></button>

   </Modal.Header>
   <Modal.Body>
  <div className='approvetransaction'>
     <p className='mt-4 receives'>Burn NFT</p>
     <p className=" gray_txt heading text-center">
                Your About to{" Burn"}

                <span className="modal_hightlights">{item?.NFTName}</span>
              </p>
     <p className="gray_txt heading text-center">
                You only own <span className="modal_hightlights">{owner?.NFTBalance}</span>
                Quantity
    </p>
     <div>
   <div>  <input
                type="text"
                placeholder="Enter Quantity to Burn"
                className="custom_input border_bot_input modal_input w-100"
                value={NFTQuantity}
                onChange={(e) => { 
                  SetBtn('start');
                  SetNFTQuantity(e.target.value)
                  SetError("")
                 }}
                disabled={item?.TokenQuantity==1||item?.ContractType==721? true : false}
                required="required"


              />
</div>
{Error?.NFTQuantity && <span className="text-danger text-left img-file">{Error?.NFTQuantity}</span>}

     </div>
     <div className='connecwalletbtns d-flex justify-content-between mt-5'>
         {/* <button className="connectwallet primary">Cancel Order</button> */}
         <Button 
         disabled    =   {Btn === "process"  ||  Btn ==="done" ? true : false} 
         className="vert__goldCmnBtn my-3"onClick={()=> {closePop(); onhide();}}>Cancel</Button>
         <Button
                                    type        =   "button"
                                    // className   =   "connectwallet secondary"
                                    //  className="connectwallet primary my-3"
                                    className="vert__goldCmnBtn my-3"
                                    disabled    =   {Btn == 'error' || Btn === "process"  ||  Btn ==="done" ? true : false} 
                                    onClick     =   {Btn == 'start' || Btn === "try" ? FormSubmit : null}
                                >   
                                {Btn == 'start' && 'Start' 
                                ||Btn == 'try' && 'Try-Again'
                                ||Btn == 'error' && 'Error' 
                                ||Btn == 'done' && 'Done' 
                                ||Btn == 'process' && 'In-Progress' 
                                 }
                                </Button>

         </div> 
  </div>
   </Modal.Body>
 </Modal>
}