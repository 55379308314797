
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react' 
import { Col, Container, Row } from 'react-bootstrap';
import Doodles from '../../assets/images/vertendi/lotties/doodles.json'
import PlainPlayer from '../../assets/images/vertendi/lotties/plainPlayer.json'
import { getCmsContent, getabouuser } from '../../actions/axioss/cms.axios';
import config from '../config/config'
import { NavLink } from 'react-router-dom';

const AboutLanding = () => {
    const [abouttop, Setabouttop] = useState({})

    const Aboutus = async () => {
        // var aboutuser=await getabouuser()
 
        var resp = await getCmsContent();

        if (resp?.status) {
            var aboutustop = await resp?.data?.filter(character => character?.slug === "aboutus_top")
            var aboutusmiddle = await resp?.data?.filter(character => character?.slug === 'aboutus_middle')
            Setabouttop(aboutustop && aboutustop[0])
            // setaboutmiddle(aboutusmiddle && aboutusmiddle[0])
            // setaboutvide(aboutusvideo && aboutusvideo[0])
            // setabtmid1(aboutusmiddle1 && aboutusmiddle1[0])
            // setabtmid2(aboutusmiddle2 && aboutusmiddle2[0])
            // setabtmid3(aboutusmiddle3 && aboutusmiddle3[0])
        }

        // if (resp?.status)
        // Setabouttop(resp.data);
    }

    useEffect(() => {
        Aboutus();
    }, [])
    return (
        <section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="aboutSectinPlayer">
          <Lottie animationData={PlainPlayer} loop />
          </div>
          <img src={require('../../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <Container className="custom__vertContainer">
          <div className="vert__aboutCont">
            <Row className="align-items-center flex-column-reverse flex-md-row">
              <Col md={5}>
                <div className="text-center">
                  <img src={`${config.IMG_URL}/cmsimg/${abouttop?.img}`} className="vert__aboutLeftImg" />
                </div>
              </Col>
              <Col md={7} className="py-2 py-md-3 py-xl-5 px-md-4 px-lg-5 mb-5 mb-lg-0">
                <div className="about__rightContHolder">
                <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont pl-md-4 pl-xl-5">{abouttop?.description}</h2>
              <p className="firaFont vert__cmnMidHint my-3 mt-sm-3 mb-sm-4 mt-lg-4 mb-lg-5 pl-md-4 pl-xl-5">
              <div dangerouslySetInnerHTML={{ __html: abouttop?.answer?.length > 100 ? abouttop?.answer.slice(0, 900) : abouttop?.answer }} />
              </p>
              <NavLink to='/aboutus'>
<button className="vert__bannerBtn firaFont ml-md-4 ml-xl-5">See More<img src={require('../../assets/images/vertendi/roundCross.svg').default} className="ver__bannerArrow ml-3" /></button>
</NavLink>
                </div>
              </Col>
            </Row>
          </div>
          </Container>
          </section>
    )
}
export default AboutLanding ; 