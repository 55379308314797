import { useSelector } from 'react-redux';
import ERC721 from '../Abi/erc721.json'
import ERC1155 from '../Abi/erc1155.json'
import DETH from '../Abi/erc20.json'
import Market from '../Abi/market.json'
import config from '../views/config/config'
import Web3 from 'web3';
import Web3Utils from 'web3-utils'
import { network } from '../views/config/network';
import bnblocal from '../Abi/bnblocal.json'
import { NftbalanceUpdate } from './axioss/nft.axios'
// var web3s=new Web3(network[Network].rpcUrl)
// console.log("web3s@123",network);


export default function useContractProviderHook() {
    const { Network } = useSelector(
        (state) => state.LoginReducer
    );

    var web3s = new Web3(network[Network]?.rpcUrl)
    const { accountAddress, web3, web3p, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);


    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const contrat_connection = async (...data) => {
        console.log('contrat_connection', ...data, web3, accountAddress);
        console.log('contrat_connectionddddaaattaaaa', ...data);

        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const GetApproveStatus = async (data, Addr) => {

        try {
            var ConnectContract = await contrat_connection(data.toLowerCase() === 'Single'.toLowerCase() ? ERC721 : ERC1155, Addr)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .isApprovedForAll(accountAddress, network[Network]?.tradeContract)
                    .call()
           
            return contract_Method_Hash

        }
        catch (e) {
            console.error("GetApproveStatus:>" , e)
            return 'error'
        }
    }

    const GetGasFees = async (encoded, contractaddress, value) => {
        try {
            var gasPrice = await web3.eth.getGasPrice();
            // console.log('skjfffffffssssss@124',chain,TradeAddress);
            var gasdata;
            if (value) {
                gasdata = await web3.eth.estimateGas({
                    from: accountAddress,
                    to: contractaddress,
                    value: value,
                    data: encoded,
                });
            }
            else {
                gasdata = await web3.eth.estimateGas({
                    from: accountAddress,
                    to: contractaddress,
                    data: encoded,
                });
            }

            console.log('hdgdgkdggd', gasPrice, gasdata)
            return ({ gasdata: gasdata, gasPrice: gasPrice });
        }
        catch (err) {
            console.log('errorrr', err);
            return undefined;
        }
    }

    const SetApproveStatus = async (data, Addr) => {
        console.log("SETAPPROVETRADExxx", Network , network);
        
        console.log("SETAPPROVETRADE", network[config.NETWORK]?.tradeContract, accountAddress);
        console.log("sdefsedffe", data, Addr);
        try {

            var ConnectContract = await contrat_connection(data === 'Single' ? ERC721 : ERC1155, Addr)
            var contractobj = await ConnectContract
                    .methods
                    .setApprovalForAll(network[config.NETWORK]?.tradeContract, true)
                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .setApprovalForAll(network[config.NETWORK]?.tradeContract, true)
                    .send({
                        from: accountAddress,
                        gas: web3.utils.toHex(gas_estimate),
                        gasPrice:  web3.utils.toHex(gasprice),
                    }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;

        }
        catch (e) {
            console.log("ERR", e);
            return false
        }
    }
    const get_receipt = async (HashValue) => {
        var receipt = await web3.eth.getTransactionReceipt(HashValue);
        console.log("sdsadshadsadhfsa", receipt, HashValue, network[Network]?.rpcUrl)
        if (receipt) {
            return receipt
        }
        else {
            get_receipt(HashValue)
        }
    }
    const minting_721_1155 = async (...data) => {
        console.log("Multiplxxedata",data);
        
        try {
            const ConnectContract = await contrat_connection(Market, network[Network]?.tradeContract)
            console.log("ConnectContractConnectContract",Network,network[Network]?.tradeContract);

            // ConnectContract


            // let encoded = await ConnectContract.methods.minting( ...data)
            
            //.encodeABI();
            // const Transcation = {
            //     from: Wallet_Details.UserAccountAddr,
            //     // to: Provider.networkConfiguration.TradeContract,
            //     // data: encoded,
            //     value: Arg.PriceWithSerfee
            // };
            // var gasprice = await Provider.Web3Pro.eth.getGasPrice();
            // var gaslimit = await encoded.estimateGas(Transcation)
            // var gasLimit = Provider.Web3Pro.utils.toHex(gaslimit);
            // var gasPrice = Provider.Web3Pro.utils.toHex(gasprice);
        

            const aggressiveGasPrice = web3.utils.toWei('100', 'gwei');  // Set to a high Gwei value

            const txObject = {
                from: accountAddress,  // Replace with your address
                gas: 21000,  // Gas limit for a simple transaction
                gasPrice: aggressiveGasPrice  // Set the aggressive gas price
            };

            // { from: accountAddress,
            //     gas: web3.utils.toHex(gas_estimate),
            //     gasPrice:  web3.utils.toHex(gasprice)
            //  }
            console.log("accountAdxxxdress" , accountAddress)

            var contractobj = await ConnectContract.methods.minting(...data)
            console.log("PASS_contractobj")
                    var gasprice = await web3.eth.getGasPrice();
            console.log("PASS_getGasPrice")

                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
                    // var gas_estimate = 0

            console.log("PASS_gas_estimate")

                    console.log('gas_estimate' , gas_estimate , 'gasprice' , gasprice)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .minting(...data)
                    .send(
                        { from: accountAddress,
                            gas: web3.utils.toHex(gas_estimate),
                            gasPrice:  web3.utils.toHex(gasprice) 
                         })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
                    console.log('beeeeeeeeeeeeeeeeeeeeeeexxx', contract_Method_Hash)

            const receipt = await get_receipt(contract_Method_Hash?.transactionHash ? contract_Method_Hash?.transactionHash : contract_Method_Hash);
            console.log('reciepppttttt', receipt)
            // var TokenCOunts = Web3Utils.hexToNumber(receipt?.logs[2]?.topics[3]) // for production 
            var TokenCOunts = Web3Utils.hexToNumber(receipt?.logs[1]?.topics[2]) // for demo and local 

            console.log('TokenCOuntsTokenCOunts', TokenCOunts)
          
            if (TokenCOunts) {
                var need_data = {
                    status: receipt?.status,
                    HashValue: receipt?.transactionHash,
                    tokenCounts: TokenCOunts
                }
                return need_data
            }
        }
        catch (e) {
            console.error("miniting_721_1155error", e)
            return false
        }


    }
    const approve_721_1155 = async (token_address, ...data) => {
        
        console.log("approvexdata", token_address, ...data)
        try {
            const ConnectContract = await contrat_connection(DETH, token_address)
            var contractobj = await
                ConnectContract
                    .methods
                    .approve(...data)
                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .approve(...data)
                    .send({ 
                        from: accountAddress,
                         gas: web3.utils.toHex(gas_estimate),
                        gasPrice:  web3.utils.toHex(gasprice)
                     })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            console.log("contract_Method_Hash", contract_Method_Hash, receipt);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("err in approve", e)
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {

        try {
            console.log('adddrreeeessss', token_Address, data)
            const ConnectContract = await contrat_connection(DETH, token_Address)
            const bidAMt = await ConnectContract.methods.balanceOf(data).call();
console.log('Token_Balance_Calculation' , bidAMt)
            // return Number(web3.utils.fromWei(String(bidAMt)))
            return Number(bidAMt / 1e6)
        }
        catch (e) {

            return 0
        }
    }
    var buy_bid_price_calculation = (val, decimal) => {
        console.log("buy_bid_price_calculation_init", val, decimal)
        var toMid = val * 1000000
        var serfee = (toMid / 100000000) * (web3.utils.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        console.log("buy_bid_price_calculation_cal serfee", serfee , buyerFees , web3.utils.fromWei(String(buyerFees ? buyerFees : 1))  ) 

        var totfee = serfee + toMid
        var tot2cont = web3.utils.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        
        var dec = decimal == 18 ? 18 : 18 - (decimal);
        // var dec = decimal == 18 ? 18 : decimal;
        console.log("buy_bid_price_calculation_cal tot2cont", tot2cont , dec) 

        var aprrove = ((tot2cont) / (10 ** dec))
        console.log("buy_bid_price_calculation_cal", val, decimal , aprrove)
const amount = Number(aprrove) / (10 ** decimal) 
        // return (aprrove)
        return (amount)

    }
    // var buy_bid_price_calculation = (valx, decimal) => {
    //     const val = valx ?? 0 
    //     console.log("buy_bid_price_calculation_init", val, decimal);
    //     var toMid = val  * (10 * decimal);
    //     var buyerFeePercent = buyerFees ? web3.utils.fromWei(String(buyerFees)) : 1;
    //     var serfee = (toMid * Number(buyerFeePercent)) / 100;
        
    //     console.log("buy_bid_price_calculation_cal serfee", serfee, buyerFees, buyerFeePercent);
    //     var totfee = toMid + serfee;
    //     var tot2cont = web3.utils.toWei(String(totfee / (10 ** decimal)));
    //     var dec = decimal; // No need to adjust for decimals beyond the token's decimal value
    //     console.log("buy_bid_price_calculation_cal tot2cont", tot2cont, dec);
     
    //     var approve = Number(tot2cont) / (10 ** dec);
    //     console.log("buy_bid_price_calculation_cal", val, decimal, approve);
    
    //     return approve;
    // }
    
    const cancel_order_721_1155 = async (data) => {
        try {
            var ConnectContract = await contrat_connection(Market, network[Network]?.tradeContract)
            var contractobj = await
            ConnectContract
                .methods
                .cancelOrder(data)
                var gasprice = await web3.eth.getGasPrice();
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .cancelOrder(data)
                    .send({
                         from: accountAddress ,
                         gas: web3.utils.toHex(gas_estimate),
                        gasPrice:  web3.utils.toHex(gasprice),})
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (e) {
            return false
        }

    }
    var price_calculation = (data, roy) => {
        try {
            var price = web3.utils.toWei(data);
            // var weii = price*1e6
            // var ser_per = (weii/100000000)*((Wallet_Details.sellerfee/config.decimalvalues)*1000000)
            // var roy_per = (weii/100000000)*((roy)*1000000)
            // var mulWei = ((weii) - (ser_per+roy_per));
            // var getVal = Number(mulWei)/1e6;
            //console..log("you will get lower",price,weii,ser_per,roy_per,getVal);
            var service_from_val = ((price * (sellerFees)) / 1e20)
            var royal_from_val = ((price * (roy * 1e18)) / 1e20)
            var my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            //console(data, getVal, sellerFees, my_val, my_val_royal)
            return getVal

        }
        catch (e) {
            return false
        }
    }
    const place_order_721_1155 = async(...data) => {
        try {
            var ConnectContract = await contrat_connection(Market, network[Network]?.tradeContract)
            var contractobj = await
            ConnectContract.methods
                .orderPlace(...data)
                var gasprice = await web3.eth.getGasPrice();
                var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
            var contract_Method_Hash = await
                ConnectContract.methods
                    .orderPlace(...data)
                    .send({
                         from: accountAddress,
                         gas: web3.utils.toHex(gas_estimate),
                         gasPrice:  web3.utils.toHex(gasprice)
                         })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
         console.log('place_order_721_1155' ,receipt , receipt.status)
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("enakenemnfsd", e)
            return false
        }

    }
    const buy_721_1155 = async (Send, CoinName, ...data) => {
        try {
            
            console.log("buy_721_1155logssss",  ...data , "accoundaddress" , accountAddress);
            const ConnectContract = await contrat_connection( Market, network[Network]?.tradeContract)
            console.log("ConnectContractbuy", ConnectContract);
            if (CoinName.toLowerCase() === config.CHAIN_TWO_COINNAME.toLowerCase() || CoinName.toLowerCase() === config.CHAIN_ONE_COINNAME.toLowerCase()) {
                
                var contractobj = await
                ConnectContract
                    .methods
                    .saleToken(...data)
                    var gasprice = await web3.eth.getGasPrice();
console.log("gasprice_buyy_accountAddress"  , accountAddress)
                    
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress , value : Send })
console.log("XXX_buyy" , gas_estimate)
                var contract_Method_Hash = await ConnectContract
                        .methods
                        .saleToken(...data)
                        .send({
                            from: accountAddress,
                            value: Send,
                            gas: web3.utils.toHex(gas_estimate),
                            gasPrice:  web3.utils.toHex(gasprice),
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
console.log("buygasss_buyy" , contract_Method_Hash)

            }
            else {
                console.log("salewith token",data);
                var contractobj = await
                ConnectContract
                    .methods
                    .saleWithToken(CoinName, ...data)
                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleWithToken(CoinName, ...data)
                        .send({ from: accountAddress,
                            gas: web3.utils.toHex(gas_estimate),
                            gasPrice:  web3.utils.toHex(gasprice) })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            console.log("contract_Method_Hash", contract_Method_Hash);

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            console.log('buyyyyyyyyrecpi', receipt)
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            console.log("need_data", need_data);
            return need_data
        }
        catch (e) {
            console.log("error_on_buy_721_1155", e);
            return false
        }

    }
    const allowance_721_1155 = async (token_Address, data,trade) => {

        try {
            const ConnectContract = await contrat_connection(DETH, token_Address)
            console.log("cPNSDFSDFGHB",ConnectContract);
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data,trade)
                    .call()

            console.log("cheackapprovecalxxxxla",contract_Method_Hash);
            return contract_Method_Hash.toString()

        }

        catch (e) {
            console.error(e)
            return false
        }

    }
    const accept_721_1155 = async (...data) => {
        try {
            console.log("accept_721_1155", ...data);
            if (web3 != null) {
                const ConnectContract = await contrat_connection(Market, network[Network]?.tradeContract)
                console.log("ConnectContract", ConnectContract);
                var contractobj = await
                ConnectContract
                    .methods
                    .acceptBId(...data)
                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress })
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)
                        .send({ from: accountAddress ,
                            gas: web3.utils.toHex(gas_estimate),
                            gasPrice:  web3.utils.toHex(gasprice),})
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }
        }
        catch (e) {
            console.error("accept_721_1155", e);
            return false
        }

    }


    const GetOwner = async (data, Addr, Tokenaddr) => {
        console.log('functioninputtt', data, Addr, Tokenaddr)
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            console.log('coooonnnnn', ConnectContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .ownerOf(Tokenaddr)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            console.log('errrorrrr', e)
            return 'error'
        }
    }

    const GetContractOwner = async (data, Addr) => {
        console.log('functioninputtt', Addr)
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            console.log('coooonnnnn', ConnectContract)
            var contractowner = await
                ConnectContract
                    .methods
                    .owner()
                    .call()
            return contractowner

        }
        catch (e) {
            console.log('errrorrrr', e, String(e))
            return 'error'
        }
    }

    const Current_NFT_Balance = async (ownerdet, data) => {
        console.log("dataincurrentnftbalance" , ownerdet , data )
        try {
        console.log('Current_NFT_BalanceContract' , data?.ContractAddress)
            var currbalance;
            if (data?.ContractType === "721" || data?.ContractType === 721) {
                console.log("dataindsddas" , ERC721 ,data?.ContractAddress )
                const ConnectContract = await contrat_connection(ERC721, data?.ContractAddress)
                console.log('ConnectContract:721' , ConnectContract , ownerdet?.NFTId)
                currbalance = await ConnectContract.methods.ownerOf(ownerdet?.NFTId).call();
                
                console.log('ballllaanneceeee1111', currbalance)
                if ((String(currbalance).toLowerCase()) == (String(ownerdet?.NFTOwner).toLowerCase())) { 
                    console.log('ballllaanneceeee22222', currbalance)
                    return currbalance; 
                   }
                else {
                    let payload = {
                        NFTId: ownerdet?.NFTId,
                        NFTOwner: ownerdet?.NFTOwner,
                        NFTBalance: "0",
                        Currentowner : currbalance , 
                        type : '721',
                        contractType : '721'

                    }
                    console.log("datainbalanceceheck721" , payload )
                    let response =  await NftbalanceUpdate(payload);
                    console.log("afterbalancecheck" , response)
                   
                     return String(currbalance);
                }
              
            }
            else {
                const ConnectContract = await contrat_connection(ERC1155, data.ContractAddress)
                currbalance = await ConnectContract.methods.balanceOf(ownerdet.NFTOwner, ownerdet.NFTId).call();
                console.log('ballllaanneceeee', currbalance)
                if ((currbalance !== null && currbalance !== undefined ) && ownerdet.NFTBalance != currbalance   ) {
                    console.log("dataincontrac1155" ,  ownerdet.NFTBalance , currbalance  )
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: currbalance,
                        Currentowner : currbalance,
                        type : '1155',
                        contractType : '1155'


                    }
                    console.log("datainbalancecheck1155" ,  payload  )
                let result  =     await NftbalanceUpdate(payload);
                    console.log("checkotherbalan3ERC1155", result)
                return String(currbalance);

                }
                console.log('ballllaanneceeee', currbalance)
                return String(currbalance);
            }
            
        }
        catch (e) {
            console.log('balacecheckerroer', e)
            console.log('balacxxxxxxxxroer', e.message)
            console.log('balacxxxxxxxx', e.message.toLowerCase().includes("execution reverted: ERC721: invalid token ID".toLowerCase()) )



            // {
            //     "code": 3,
            //     "message": "execution reverted: ERC721: invalid token ID",
            //     "data": "0x08c379a0000000000000000000000000000000000000000000000000000000000000002000000000000000000000000000000000000000000000000000000000000000184552433732313a20696e76616c696420746f6b656e2049440000000000000000",
            //     "cause": null
            //   }
            if(e.message.toLowerCase().includes("execution reverted: ERC721: invalid token ID".toLowerCase()) ){
                    let payload = {
                        NFTId: ownerdet?.NFTId,
                        NFTOwner: ownerdet?.NFTOwner,
                        NFTBalance: "0",
                        Currentowner : currbalance , 
                        type : 'burn' ,
                        contractType : '721'
                    }
                    console.log("balacxxxxxxxxEroorrr" , payload )
                    let response =  await NftbalanceUpdate(payload);
                    console.log("balacxxxxxxxxEroorrr:BURN" ,response )
            }
            return false
        }
    }


    const Subcribe_ContractCALL = async (walletaddress , value )=>{
        try{
            console.log('Subcribe_ContractCALL' , 'started' , Market , Network);
           
            const ConnectContract = await contrat_connection(Market , network[Network]?.tradeContract) 
            const contractobj = await ConnectContract.methods.subscribe()

            const gasprice = await web3.eth.getGasPrice();
            console.log('Subcribe_ContractCALL' , 'gasprice' , gasprice);
            // const gas_estimate  = gasprice

            const gas_estimate = await contractobj.estimateGas({ from: walletaddress })
            console.log('Subcribe_ContractCALL' , 'gas_estimate' , gas_estimate);
            const  contract_Method_Hash = await
                ConnectContract
                    .methods
                    .subscribe()
                    .send({
                        from: walletaddress,
                        value : value , 
                        gas: web3.utils.toHex(gas_estimate),
                        gasPrice:  web3.utils.toHex(gasprice),
                    }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
                    console.log('Subcribe_ContractCALL',"SUBCRIBEED" , contract_Method_Hash )
            // const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            // var need_data = {
            //     status: receipt.status,
            //     HashValue: receipt.transactionHash,
            // }
            // return need_data;
        }catch(e){
console.error('Error : Subcribe_ContractCALL' , e)
        }
    }

    const Send_Amount = async (userAddress , amount) => {
try{

    const value =  web3.utils.toWei(amount.toString(), 'ether')

        let estimateGasFee = await web3.eth.estimateGas({
            from: userAddress,
            to: config.ADMINADDRESS,
            value: value
        });
        const gasPrice = await web3.eth.getGasPrice();
        const sendUserToAdmin = await web3.eth.sendTransaction({ from: userAddress, to: config.ADMINADDRESS, value: value, gas: estimateGasFee, gasPrice });
const receipt = await get_receipt(sendUserToAdmin.transactionHash);

return {
    status : sendUserToAdmin.status,
    transactionHash : sendUserToAdmin.transactionHash
}
    }catch(e){
        console.log('Send_Amount Error' , e)
        return {
            status : false,
            message : e
        }

    }    
}


const burn_721_1155 = async (ContractAddress,ContractType,Quantity,Add,Id) => {
    console.log("fertaea",ContractAddress,ContractType,Quantity,Add,Id);
    try {

            const ConnectContract = await contrat_connection((ContractType === "721" || ContractType === 721 )?  ERC721 : ERC1155,ContractAddress)
           if(ContractType === "721"|| ContractType === 721)
            {                
                var contractobj = await
                ConnectContract
                    .methods
                    .burnNFT(Id)

                    var gasprice = await web3.eth.getGasPrice();
                    var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 
                
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .burnNFT(Id)
                        .send({ 
                            
                         from: accountAddress,
                         gasLimit: parseInt(gas_estimate), 
                         gasPrice: gasprice,
                         })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                    }
                    else{
                        var contractobj = await
                        ConnectContract
                            .methods
                            .burnNFT(Id,Quantity)
        
                            var gasprice = await web3.eth.getGasPrice();
                            var gas_estimate = await contractobj.estimateGas({ from: accountAddress }) 
                         
                            var contract_Method_Hash = await
                        ConnectContract
                            .methods
                            .burnNFT(Id,Quantity)
                            .send({ 
                                from: accountAddress ,
                                gasLimit: parseInt(gas_estimate), 
                                gasPrice: gasprice,
                            })
                            .on('transactionHash', (transactionHash) => {
                                return transactionHash
                            })}
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                      }
                return need_data
            }
            catch (e) {
console.log("catch",e);
                return false
            }

        }


    return {
        Send_Amount,
        Subcribe_ContractCALL,
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        minting_721_1155,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        cancel_order_721_1155,
        price_calculation,
        place_order_721_1155,
        buy_721_1155,
        allowance_721_1155,
        accept_721_1155,
        GetOwner,
        GetContractOwner,
        Current_NFT_Balance,
        burn_721_1155
    };




}
