import React,{useState,useEffect} from "react";
import { Sociallinks , Newsletter} from "../actions/axioss/user.axios";
import config from "../views/config/config"
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import { IoMdMail } from "react-icons/io";
import discard from "../assets/images/DiscordLogo.png";
import whatsapp from '../assets/images/whatsapp (1).png'
import facebook from '../assets/images/facebook.png'
import telegram from '../assets/images/telegram.png'
import linkedin from '../assets/images/linkedin.png'
import you from "../assets/images/YoutubeLogo.png";
import twitter from "../assets/images/TwitterLogo.png";
import instagram from "../assets/images/InstagramLogo.png";
import { getCmsContent } from "../actions/axioss/cms.axios";
import { Container } from "react-bootstrap";

export default function Footer(props){
    var [email,setEmail] = useState('');
    var [Error,SetError] = useState({});
    // const navigate  = useNavigate();

    // const [click, setClick] = useState(false);
    // const gotoLive = () =>    
    // {
    //     setClick(true);
    //     setTimeout(()  =>
    //     {
    //         navigate("/");
    //         if((!isEmpty(props)))
    //         {
    //             console.log(click,"click")
    //             props?.func("liveauction",true)
          
            
    //     },1000);
      
    // }
   
    
    // if((!isEmpty(props)))
    // {
    //     console.log(click,"click")
    //     props?.func("",false)
    // }
   
  
    // props? props?.func("#liveaution") : null;
    // console.log(props,"props");


    // const gotoHome = (val) =>
    // {
    //     alert(1)
        
    //     navigate("/");
       
    // }
    const scrollTo = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
      }
      const wallet = useSelector(state => state.LoginReducer.AccountDetails);
    var [link,setLink] = useState([]);
    var [Error,SetError] = useState({});
  const [footer,setFooter]=useState({})
    const { payload } = useSelector(state => state.LoginReducer.User)

      const push = useNavigate()
    useEffect(()=>{
        var a = Getlink();
        Getandsellnft();
        // console.log("hello",a)
    },[])
    const Getandsellnft = async () => {

        var resp = await getCmsContent("footer");
        console.log("footerrr",resp?.data)
        if (resp?.status)
            setFooter(resp.data);
    }

    const Getlink=async()=>{
        
        let link_res= await Sociallinks();
        console.log("linkss",link_res?.msg);
        setLink(link_res?.msg??[])
    }

    const NewsLetter = async() =>{
        const id = toast.loading("Subscribing...");
        
        let err = {};
        if(email=='') err.email="Email Id Required";
        if(email && !(config.EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if(isEmpty(err)){
            let resp = await Newsletter({email : email});
            if(resp.success === 'success'){
                toast.update(id ,{render:"Successfully Subscribed", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                setEmail('');
            }
            else{
                toast.update(id ,{ render : "Already User", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                SetError({email:resp.msg});
            }
        }
        else{
            toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
        }
    }

    return(
        <>
          <footer className="footer footer_new vert__footerer">
                <Container className="custom__vertContainer footer__bgcontainer pt-5 pl-4 pl-md-5 pr-4 pr-md-5 pb-3">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget widget-infor mb-3">
                                <div className="logo">
                                    <NavLink to="/"><img src={require('../assets/images/vertendi/logo.svg').default} className="footer__mainLogo" /></NavLink>
                                </div>
                                <div className="subheads firaFont" dangerouslySetInnerHTML={{ __html: footer?.answer }}></div>
                               
                                {/* <p className="content">NFT marketplace UI created with Anima for Figma.</p> */}
                             
                                
                                {/* <p className="copy-right">Copyright © 2022 Ditmax. All Rights Reserved.</p> */}
                            </div>
                            {/* <a href="mailto:support@naifty.io" className="footermailtag d-flex align-items-center"><IoMdMail className="mailcon"/> <span className="ml-2"> support@naifty.io</span></a> */}
                            <h2 className="vert__footerGreenTitle firaFont">Join Our Community</h2>
                            <div className="vert_footerSocialHolder">

                            <ul className="social-item">
                                  
                                  {link.map((mlink)=>(  
                                      <>
                                   {    console.log(mlink,"mlixxxnk")}
                                      
                                      {
                                 (mlink.website).toLowerCase()==="twitter"&&  mlink.link  &&
                                      <a href={mlink.link} target="blank">
                                          
                                          <img src={require('../assets/images/vertendi/TwitterLogo.svg').default} className="img-fluid" />
                                          </a>
                                          }
                                     { 
                                     (mlink.website).toLowerCase()==="youtube" && mlink.link &&
                                      <a href={mlink.link} target="blank">
                                      <img src={require('../assets/images/vertendi/YoutubeLogo.svg').default} className="img-fluid" /> 
                                         
                                          </a>
                                          }
                                          
                                          {(mlink.website).toLowerCase() ==="instagram" && mlink.link   && 
                                      <a href={mlink.link} target="blank">
                                          
                                          <img src={require('../assets/images/vertendi/instagram.svg').default} className="img-fluid" /> 
                                          </a>
                                          }
                                      {
                                      (mlink.website).toLowerCase()==="discord" && mlink.link   && 
                                      <a href={mlink.link} target="blank">
                                        
                                        <img src={require('../assets/images/vertendi/DiscordLogo.svg').default} className="img-fluid" /> 
                                          </a>
                                          }
                                      </>
                                      ))}
                                  </ul> 

                                {/* <img src={require('../assets/images/vertendi/DiscordLogo.svg').default} className="img-fluid" /> 
                                <img src={require('../assets/images/vertendi/instagram.svg').default} className="img-fluid" /> 
                                <img src={require('../assets/images/vertendi/YoutubeLogo.svg').default} className="img-fluid" /> 
                                <img src={require('../assets/images/vertendi/TwitterLogo.svg').default} className="img-fluid" />  */}
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-5 col-lg-8 col-md-6">
                            <div className="widget widget-menu">
                                <div className="menu menu-1">
                                    <h6 className="widget-title firaFont mb-3">Marketplace</h6>
                                    <ul >
                                        <li className="firaFont"><Link to="/explore">Explore</Link></li>
                                        {/* <li className="firaFont"><Link to="/">Collection</Link></li> */}
                                   
                                   {wallet && wallet.accountAddress && <li><Link to="/create">Create</Link></li> }     
                                    
                                        {/* <li><Link to="#" onClick={()=>{push ('/')
document.getElementById('liveauction').scrollTo({behaviour:"smooth"})
}}>Live Auction</Link></li> */}
                                {/* <li><Link to="/"
                                >Live Auction</Link></li> */}

                                    </ul>
                                </div>
                                <div className="menu menu-2">
                                    <h6 className="widget-title firaFont mb-3">Stats</h6>
                                    <ul >
                                        {/* <li><Link to="#">Ranking</Link></li> */}
                                        {/* <li className="firaFont"><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Activity</Link></li> */}
                                       { wallet && wallet.accountAddress ?  <li className="firaFont"><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Activity</Link></li>
                                        : 
                                        <li className="firaFont">Activity</li>

                                }
                                        {/* <li><Link to={`/profile/${payload?.CustomUrl}`}>Profile</Link></li> */}
                                    </ul>
                            </div>
                                <div className="menu menu-3">
                                    <h6 className="widget-title firaFont mb-3">Resource</h6>
                                    <ul>
                                        {/* <li><Link to="#">Blogs</Link></li> */}
                                        {/* <li><Link to="/helpandcentre">Help and Center</Link></li> */}
                                        <li className="firaFont"><Link to="/privacypolicy">Privacy Policy</Link></li>
                                        <li className="firaFont"><Link to="/faq">Faq</Link></li>
                                        {/* <li><Link to="/contactus">Contactus</Link></li> */}
                                        <li className="firaFont"><Link to="/aboutus">AboutUs</Link></li>
                                        <li className="firaFont"><Link to="/blog">Blog</Link></li>
                                          {/* <li className="firaFont"><Link to="/blog">Terms & Conditions</Link></li>*/}
                                    </ul>
                                </div>
                                {wallet && wallet.accountAddress ?
                                <div className="menu menu-4">

                                    <h6 className="widget-title firaFont mb-3">My account</h6>
                                    <ul >
                                        {/* <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li> */}
                                        <li className="firaFont"><Link to={`/profile/${payload?.CustomUrl}`}>Profile</Link></li>
                                        {/* <li><Link to="/connect">Wallet</Link></li> */}
                                        <li className="firaFont"><Link to={`/profile/${payload?.CustomUrl}`} state={{Tab : 'owned'}}>My item</Link></li>

                                    </ul>
                                </div> :  <div className="menu menu-4">

<h6 className="widget-title firaFont">My account</h6>
<ul >
    {/* <li><Link to={payload?.CustomUrl ? `/profile/${payload?.CustomUrl}`: "#"}>Profile</Link></li> */}
    <li className="firaFont">Profile</li>
    {/* <li><Link to="/connect">Wallet</Link></li> */}
    <li className="firaFont">My item</li>

</ul>
</div>}
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-12">
                            <div className="widget widget-subcribe">
                                <h6 className="widget-title firaFont">Subscribe Us</h6>
                                <p className="content firaFont">Get exclusive promotions & updates straight to your inbox.</p>
                                <form id="subscribe-form">
                                    <input type="email" placeholder="Enter your email" value={''||email} onChange={(event)=>{setEmail(event.target.value)}} required="" id="subscribe-email" className="footer_email_inpt firaFont" autoComplete="off" />
                                    <button className="tf-button firaFont" type="button" id="subscribe-button" onClick={NewsLetter}>
                                        {/* <i className="icon-fl-send"></i> */}Subscribe
                                        </button>
                                </form>

                               
                                {/* <p className="join">Join our community</p> */}
                              
                            </div>
                        </div>

                        <div className="col-md-12 footer_line">
                            <div className="flex_content_footer2">
                            <p className="copy-right foot-p text-center firaFont">Copyright © {new Date()?.getFullYear()}. All Rights Reserved.</p>
                            {/* <ul className="social-item mt-4">
                                  
                                  {link.map((mlink)=>(  
                                       
                                      <>
                                      {
                                         <a href={mlink.link} target="blank">
                                          
                                         <img src={`${config.IMG_URL}/socialimg/${mlink?.img}`} alt="dd"/>
                                         </a>
                                      }
                                      
                                     
                                      </>
                                      ))}
                                  </ul> */}
                            </div>
                       
                        </div>
                    </div>
                </Container>
                <Link to="#" id="scroll-top" className="scrolltop_zindex" onClick={scrollTo}></Link>
           </footer>
        </>
    )
}