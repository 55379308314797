import React, { useEffect, useState } from 'react'
import { TopCreatorApi } from '../../actions/axioss/nft.axios';
import config from '../config/config'
import NoData from '../seperatemodals/nodata';
import { address_showing } from '../../actions/common';
import { NavLink } from 'react-router-dom';
// import sda from '../../assets'
const TopCreator = ()=>{
    const [topCreators,setTopCreators] = useState([
        {
          img : require('../../assets/images/vertendi/creatorOne.svg').default,
          name : "MUsic_create #56"
        },
        {
          img : require('../../assets/images/vertendi/creatorTwo.svg').default,
          name : "MUsic_create #76"
        },
        {
          img : require('../../assets/images/vertendi/creatorThree.svg').default,
          name : "MUsic_create #56"
        },
        {
          img : require('../../assets/images/vertendi/creatorFour.svg').default,
          name : "MUsic_create #56"
        },
        {
          img : require('../../assets/images/vertendi/creatorFive.svg').default,
          name : "MUsic_create #76"
        },
      ])
      const [TopCreator, SetTopCreator] = useState([]);
      const [creatorstatus, setCreator] = useState(false);

      const TopCreatorFunc = async () => {
        const resp = await TopCreatorApi();
        console.log("xxxxaTopCreatorApiaa", resp);
        if (resp?.data?.length > 8) {
          SetTopCreator(resp?.data.slice(0, 8));
          setCreator(true);
        } else {
          SetTopCreator(resp?.data);
          setCreator(true);
        }
      };

      useEffect(() => {
    
        TopCreatorFunc();

      }, []);
    return (
        <div>

<div className="ver__topCreatorsCont mt-5 pt-5 align-items-lg-end">
        <h2 className="vert__subTitleGreen vert__subTtlGreeenNowrap crossfitFont">Top <br className="d-none d-lg-block" />Creators</h2>
        <div>
        <p className="firaFont vert__cmnHint vert__cmnHintXXL mb-2">Meet artists changing the digital world. From creative painters to innovative digital creators, their unique art shows the strength of blockchain in supporting artists.</p>
        {/* <p className="firaFont vert__cmnHint mb-2 vert__cmnHintXXL">their unique art shows the strength of blockchain in supporting artists.</p> */}
      </div>
      </div>

      <div className="vert__topCreatorGrid">

      {creatorstatus == false ? (   <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                    {/* <p>Nothing for ReSale at this time</p> */}
                    <div className="load-more">
                      {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                    </div>
                  </div>
                </>
              ) : TopCreator?.length > 0 ? 

              TopCreator?.map((data) => 
        <div className="vert__topCreatorSingle mb-4">
          <img     src={
                              data?.Profile
                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                : config.profile
                            }className="vert__topCreatorImg" />
        <NavLink to={`/profile/${data?.CustomUrl}`}>
          <p className="firaFont vert__topCreatorName text-center mb-3"> {data?.DisplayName
                                ? data?.DisplayName
                                : address_showing(data?.WalletAddress)}</p>
                                    </NavLink>
        </div>
        ) : (
            <NoData />
          ) }
      </div>
        </div>
    )
}

export default TopCreator